const OrangeWarningTrianlge = (height, width) => {
    return <svg viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.7186 19.0978C25.5198 20.4864 24.5142 22.2222 22.9141 22.2222H2.08569C0.482521 22.2222 -0.518434 20.4837 0.281132 19.0978L10.6955 1.04102C11.497 -0.348264 13.5045 -0.345746 14.3046 1.04102L24.7186 19.0978ZM12.5 15.3646C11.3974 15.3646 10.5035 16.2585 10.5035 17.3611C10.5035 18.4638 11.3974 19.3576 12.5 19.3576C13.6027 19.3576 14.4965 18.4638 14.4965 17.3611C14.4965 16.2585 13.6027 15.3646 12.5 15.3646ZM10.6045 8.18811L10.9264 14.0909C10.9415 14.3671 11.1699 14.5833 11.4465 14.5833H13.5535C13.8301 14.5833 14.0585 14.3671 14.0736 14.0909L14.3955 8.18811C14.4118 7.88976 14.1743 7.63889 13.8755 7.63889H11.1245C10.8257 7.63889 10.5882 7.88976 10.6045 8.18811Z" className="fill-amber-500"/>
    </svg>
}

const RedWarningTrianlge = (height, width) => {
    return <svg viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.7186 19.0978C25.5198 20.4864 24.5142 22.2222 22.9141 22.2222H2.08569C0.482521 22.2222 -0.518434 20.4837 0.281132 19.0978L10.6955 1.04102C11.497 -0.348264 13.5045 -0.345746 14.3046 1.04102L24.7186 19.0978ZM12.5 15.3646C11.3974 15.3646 10.5035 16.2585 10.5035 17.3611C10.5035 18.4638 11.3974 19.3576 12.5 19.3576C13.6027 19.3576 14.4965 18.4638 14.4965 17.3611C14.4965 16.2585 13.6027 15.3646 12.5 15.3646ZM10.6045 8.18811L10.9264 14.0909C10.9415 14.3671 11.1699 14.5833 11.4465 14.5833H13.5535C13.8301 14.5833 14.0585 14.3671 14.0736 14.0909L14.3955 8.18811C14.4118 7.88976 14.1743 7.63889 13.8755 7.63889H11.1245C10.8257 7.63889 10.5882 7.88976 10.6045 8.18811Z" className="fill-red-500"/>
    </svg>
}

export {OrangeWarningTrianlge, RedWarningTrianlge}